export const dateFormatter = new Intl.DateTimeFormat("default", {
  day: "2-digit",
  month: "short",
});

export function formatDate(date: Date) {
  return dateFormatter.format(date);
}

export function dateInWeeks(amount: number) {
  const date = new Date();
  date.setDate(date.getDate() + amount * 7);
  return date;
}

export function calculateDays(start: Date, end: Date) {
  const oneDay = 1000 * 60 * 60 * 24;
  const diff = end.getTime() - start.getTime();
  return Math.round(diff / oneDay);
}

export function calculateWeeks(startDate: Date, endDate: Date) {
  return Math.floor(calculateDays(startDate, endDate) / 7);
}

export function formatKG(value: number) {
  return Math.round((value + Number.EPSILON) * 100) / 100 + "kg";
}
