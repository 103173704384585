import { useReducer, useState } from "react";
import "react-day-picker/lib/style.css";
import "./App.css";
import { calculateWeeks, formatDate, formatKG } from "./utils";
import DayPickerInput from "react-day-picker/DayPickerInput";

interface PlanWeek {
  weekNumber: number;
  weight: { amount: number; actual: boolean };
  date: Date;
  differential?: number;
  isCurrent: boolean;
}

interface WeekLogEntry {
  amount: number;
  weekNumber: number;
}

class Plan {
  constructor(
    public startDate: Date,
    public goalDate: Date,
    public startWeight: number,
    public goalWeight: number,
    public weekLogEntries: WeekLogEntry[] = []
  ) {}

  get totalWeightToLose() {
    return this.startWeight - this.goalWeight;
  }

  get totalWeeks() {
    return calculateWeeks(this.startDate, this.goalDate);
  }

  get currentWeek() {
    return calculateWeeks(this.startDate, new Date()) + 2;
  }

  get lossRequiredPerWeek() {
    return this.totalWeightToLose / this.totalWeeks;
  }

  get weeks(): PlanWeek[] {
    const weeks = [];

    for (let i = 1; i <= this.totalWeeks; i++) {
      const date = new Date(this.startDate);
      date.setDate(date.getDate() + (i - 1) * 7);

      const logAmount = this.weekLogEntries.find(
        (logEntry) => logEntry.weekNumber === i
      );

      const expectedAmount = this.startWeight - i * this.lossRequiredPerWeek;

      let amount = expectedAmount;
      let actual = false;
      let differential;
      if (logAmount) {
        amount = logAmount.amount;
        actual = true;
        differential = logAmount.amount - expectedAmount;
      }

      weeks.push({
        weekNumber: i,
        weight: { amount, actual },
        date,
        differential,
        isCurrent: i === this.currentWeek,
      });
    }

    return weeks;
  }

  logWeight(weekNumber: number, amount: number) {
    this.weekLogEntries.push({ weekNumber, amount });
  }
}

interface PlanState {
  startDate: Date;
  goalDate: Date;
  startWeight: number;
  goalWeight: number;
  weightLogEntries: WeekLogEntry[];
}

function loadState(): PlanState | undefined {
  const appJSON = window.localStorage.getItem("appData") as any;

  if (appJSON) {
    const appData = JSON.parse(appJSON);
    return {
      ...appData,
      startDate: new Date(appData.startDate),
      goalDate: new Date(appData.goalDate),
    };
  }
}

let initialPlanState = loadState() ?? {
  startDate: new Date(Date.parse("2021-08-11")),
  goalDate: new Date(Date.parse("2021-12-31")),
  startWeight: 108.6,
  goalWeight: 90,
  weightLogEntries: [{ amount: 105, weekNumber: 1 }],
};

interface LogEntryAction {
  type: "logEntry";
  weekNumber: number;
  amount: number;
}

interface ClearAction {
  type: "clear";
  weekNumber: number;
}

interface UpdatePlanAction {
  type: "updatePlan";
  startDate: Date;
  goalDate: Date;
  startWeight: number;
  goalWeight: number;
}

type Action = LogEntryAction | ClearAction | UpdatePlanAction;

function reducer(state: PlanState, action: Action): PlanState {
  let newState = state;
  switch (action.type) {
    case "updatePlan":
      newState = {
        ...state,
        startDate: action.startDate,
        goalDate: action.goalDate,
        startWeight: action.startWeight,
        goalWeight: action.goalWeight,
      };
      break;

    case "clear":
      newState = {
        ...state,
        weightLogEntries: state.weightLogEntries.filter(
          (logEntry) => logEntry.weekNumber !== action.weekNumber
        ),
      };
      break;

    case "logEntry":
      const weightLogEntries = state.weightLogEntries.filter(
        (logEntry) => logEntry.weekNumber !== action.weekNumber
      );

      newState = {
        ...state,
        weightLogEntries: [
          ...weightLogEntries,
          {
            amount: action.amount,
            weekNumber: action.weekNumber,
          },
        ],
      };
      break;
  }

  persist(newState);

  return newState;
}

function persist(state: PlanState) {
  window.localStorage.setItem(
    "appData",
    JSON.stringify({
      ...state,
      startDate: state.startDate.getTime(),
      goalDate: state.goalDate.getTime(),
    })
  );
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialPlanState);

  const plan = new Plan(
    state.startDate,
    state.goalDate,
    state.startWeight,
    state.goalWeight,
    state.weightLogEntries
  );

  return (
    <div className="App">
      <div className="toolbar">
        <div>Outlook</div>
        <div>Calendar</div>
      </div>
      <div className="plan">
        <p className="plan-summary">
          Lose {formatKG(plan.totalWeightToLose)} in {plan.totalWeeks}
          weeks
        </p>
        <EditPlan
          onSave={(plan) => {
            dispatch({
              type: "updatePlan",
              startDate: plan.startDate,
              goalDate: plan.goalDate,
              startWeight: plan.startWeight,
              goalWeight: plan.goalWeight,
            });
          }}
          startDate={plan.startDate}
          goalDate={plan.goalDate}
          startWeight={plan.startWeight}
          goalWeight={plan.goalWeight}
        />
      </div>
      <div className="outlook">
        <p className="outlook-start-weight">{plan.startWeight}</p>
        <div className="outlook-weeks">
          {plan.weeks.map((week) => (
            <OutlookWeek
              key={week.weekNumber}
              {...week}
              onClearWeight={() =>
                dispatch({ type: "clear", weekNumber: week.weekNumber })
              }
              onLogWeight={(amount) => {
                dispatch({
                  type: "logEntry",
                  weekNumber: week.weekNumber,
                  amount,
                });
              }}
            />
          ))}
        </div>
      </div>
      <p>Goal!</p>
    </div>
  );
}

function OutlookWeek(props: OutlookWeekProps) {
  const [logWeightAmount, setLogWeightAmount] = useState(
    String(props.weight.amount)
  );
  const [isShowingForm, setIsShowingForm] = useState(false);

  return (
    <div
      className={
        "outlook-week" + (props.isCurrent ? " outlook-week-current" : "")
      }
    >
      <span className="outlook-week-badge">
        <span className="outlook-week-number">#{props.weekNumber}</span>
        <span className="outlook-week-date">{formatDate(props.date)}</span>
      </span>
      <span
        className={
          "outlook-week-amount" +
          (props.weight.actual ? " outlook-week-amount-actual" : "")
        }
      >
        {formatKG(props.weight.amount)}
      </span>
      {props.differential ? (
        <span
          className={
            "outlook-week-differential " +
            (props.differential <= 0
              ? "outlook-week-differential-under"
              : "outlook-week-differential-over")
          }
        >
          {formatKG(Math.abs(props.differential))}
        </span>
      ) : (
        ""
      )}

      {props.onLogWeight ? (
        <button
          className="outlook-week-log"
          onClick={() => setIsShowingForm(!isShowingForm)}
        >
          {isShowingForm ? "Cancel" : "Edit"}
        </button>
      ) : null}

      {isShowingForm ? (
        <div>
          <input
            type="number"
            value={logWeightAmount}
            onChange={(e) => setLogWeightAmount(e.target.value)}
          />
          <button
            onClick={() => {
              props.onLogWeight?.(parseFloat(logWeightAmount));
              setIsShowingForm(false);
            }}
          >
            Save
          </button>
          <button
            onClick={() => {
              props.onClearWeight();
              setIsShowingForm(false);
            }}
          >
            Clear
          </button>
        </div>
      ) : null}
    </div>
  );
}

interface EditPropsProps {
  goalDate: Date;
  startDate: Date;
  startWeight: number;
  goalWeight: number;
  onSave: (plan: {
    startDate: Date;
    goalDate: Date;
    startWeight: number;
    goalWeight: number;
  }) => void;
}

function EditPlan(props: EditPropsProps) {
  const [startDate, setStartDate] = useState<Date>(props.startDate);
  const [goalDate, setGoalDate] = useState<Date>(props.goalDate);
  const [startWeight, setStartWeight] = useState<string>(
    String(props.startWeight)
  );
  const [goalWeight, setGoalWeight] = useState<string>(
    String(props.goalWeight)
  );

  return (
    <div>
      <input
        type="number"
        onChange={(e) => setStartWeight(e.target.value)}
        value={startWeight}
      />
      <input
        type="number"
        onChange={(e) => setGoalWeight(e.target.value)}
        value={goalWeight}
      />
      <DayPickerInput
        value={startDate}
        onDayChange={(date) => setStartDate(date)}
      />
      <DayPickerInput
        value={goalDate}
        onDayChange={(date) => setGoalDate(date)}
      />
      <button
        onClick={() =>
          props.onSave({
            startDate,
            goalDate,
            startWeight: parseInt(startWeight, 10),
            goalWeight: parseInt(goalWeight, 10),
          })
        }
      >
        Save
      </button>
    </div>
  );
}

interface OutlookWeekProps {
  date: Date;
  differential?: number;
  weekNumber: number;
  weight: {
    amount: number;
    actual: boolean;
  };
  isCurrent: boolean;
  onLogWeight?: (amount: number) => void;
  onClearWeight: () => void;
}

export default App;
